(function () {
    "use strict";

    // @ngInject
    function ReceiptControllerCtor($scope, $injector, $stateParams, AppConfigService, AnalyticsService, UsersManager,
                                   WorkspaceFileService, AbTestService, WorkspacesManager, Enums, UserService,
                                   ModalService, FeaturesService) {

        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'ReceiptController';

        this.UserService = UserService;
        this.AnalyticsService = AnalyticsService;
        this.AbTestService = AbTestService;
        this.Enums = Enums;
        this.WorkspaceFileService = WorkspaceFileService;
        this.WorkspacesManager = WorkspacesManager;
        this.UsersManager = UsersManager;
        this.appConfig = AppConfigService;
        this.ModalService = ModalService;
        this.FeaturesService = FeaturesService;
        this.showClientLoginCompletion = $stateParams.isLoggedInAsClient;

        this.receiptState = this.appConfig.receiptState();

        this.workspaceFile = this.receiptState.workspaceFile;
        this.currentPaymentId = this.receiptState.paymentId;
        this.paymentLocation = this.workspaceFile.payments_container.getPaymentLocationById(this.currentPaymentId);
        this.payment = this.workspaceFile.payments_container.payments[this.paymentLocation];
        this.isAchPayment = this.payment.is_pending;
        this.nextClicked = false;
        this.shouldShowNext = true;

        this.isClientPortalExposed = this.workspaceFile.event.is_client_portal_exposed;

        // go over previous payments, and check if there is a paid payment
        // that was paid through hb
        var isFirstPaymentUsingHB = true;
        for (var i = 0; i < this.paymentLocation; i++) {
            var payment = this.workspaceFile.payments_container.payments[i];
            if (!payment.isMarkedAsPaid()) {
                isFirstPaymentUsingHB = false;
            }
        }

        var isLastPayment = this.payment.isLastPayment();
        this.nextPayment = isLastPayment ? null : this.workspaceFile.payments_container.payments[this.paymentLocation+1];

        ///////////////////////////////////
        // pvl related
        ///////////////////////////////////

        // if this file already has a pvl Attached to it
        var hasPreferredVendorsList = this.workspaceFile.hasPreferredVendorsList();
        var hasPendingPvlRequest = this.workspaceFile.hasPendingPvlRequest();
        var isEventTypeCompany = !this.workspaceFile.company.isNonEventType();

        // should we show the attached pvl
        this.showAttachedPvl = hasPreferredVendorsList;

        // should show pvl request form
        this.canRequestPvl = !(
            hasPreferredVendorsList ||
            hasPendingPvlRequest    ||
            isLastPayment           ||
            this.workspaceFile.owner.disable_client_pvl_requests) &&
            !this.workspaceFile.owner.isInTrialAndNotSubscribed() &&
            isEventTypeCompany;

        // if the file has a pvl attached to it
        // it means the vendor attached on send file and now,
        // that the client booked, we need to send this file
        // IMPORTANT: We need to do this only on the first hb payment
        if (this.showAttachedPvl && isFirstPaymentUsingHB) {
            this.WorkspacesManager.sendPreferredVendorsListForWSFile(this.workspaceFile.couple_card_id, this.workspaceFile._id);
        }

        // page tracking
        this.AnalyticsService.trackPageView(this, 'receipt', {
            workspace_file: this.workspaceFile._id,
            payment_tip: this.payment.tip_paid,
            payment_id: this.payment._id,
            payment_amount: this.payment.amount,
            payment_number: this.paymentLocation,
            file_owner_name: this.workspaceFile.owner.full_name,
            file_owner_email: this.workspaceFile.owner.email,
            file_owner_id: this.workspaceFile.owner._id,
            file_owner_company_name: this.workspaceFile.owner.company.company_name,
            file_owner_company_role: this.workspaceFile.owner.company_role,
            is_last_payment: isLastPayment,
            can_request_pvl: this.canRequestPvl,
        });

        this.ratingGrade = 0;
    }

    Controllers.ReceiptController = Class(Controllers.BaseController, {

        constructor: ReceiptControllerCtor,

        setRatingGrade: function (value) {
            this.ratingGrade = value;
        },

        goToWorkspace: function goToWorkspace() {
            var stateParams = {
                event_id: this.workspaceFile.event._id,
                workspace_id: this.workspaceFile.couple_card_id
            };

            this.goToState(this.AppStates.root_core_navigation_event_workspace_feed, stateParams);
        },

        beforeDestroy: function beforeDestroy(){
            this.appConfig.resetReceiptState();
        },

        printReceipt: function printReceipt() {
            this.payment.printReceipt();
        },

        analyticsProperties: function analyticsProperties(){
            return this.WorkspaceFileService.generateAnalyticsProps(this.workspaceFile);
        },

        onViewPreferredVendorList: function onViewPreferredVendorList() {
            this.gotoPreferredVendorList().then( //go to event after modal closes
                this.goToWorkspace.bind(this),
                this.goToWorkspace.bind(this)
            );
        },

        pvlViewShown: function pvlViewShown($inview, $inviewpart) {

            if($inview){
                this.shouldShowNext = false;
            }

        },

        scrollToPvl: function scrollToPvl(){

            this.nextClicked = true;
        },

        gotoPreferredVendorList: function gotoPreferredVendorList() {
            return this.WorkspaceFileService.showPreferredVendorList(this.workspaceFile);
        },

        pvlRequestSentCallback: function pvlRequestSentCallback() {
            this.goToWorkspace();
        },

        completeClientLogin: function completeClientLogin() {
            this.UserService.bookInPersonLogout(this.AppStates.root_core_workspaceFile, { workspace_file_id: this.workspaceFile._id });
        },

        getRecurringTotalPaymentCount: function getRecurringTotalPaymentCount() {
          return (this.workspaceFile.payments_container.recurring_payment && this.workspaceFile.payments_container.recurring_payment.amount_of_payments) || this.workspaceFile.payments_container.payments.length;
        },
    });
}());

