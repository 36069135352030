Directives.ClientBreadcrumbsDirective =  function ClientBreadcrumbsDirective(){

    // @ngInject
    function ClientBreadcrumbsDirectiveController(WorkspaceFileService, WorkspacesManager, AppStates, $state){

        var self = this;
        this.workspaceFileId = this.workspaceFile._id;
        this.isClientPortalExposed = this.workspaceFile.event.is_client_portal_exposed;
        //this.workspace       = WorkspacesManager.getWorkspace(self.workspaceFile.couple_card_id, true);
        //this.eventRef        = AppStates.root_core_navigation_event_overview({event_id: self.workspace.__event._id});

        this.backToWorkSpace = function backToWorkSpace () {
            $state.go(AppStates.root_core_navigation_event_workspace_feed, {workspace_id: self.workspaceFile.couple_card_id, event_id: this.workspaceFile.event._id});
        };

        this.backToEvent = function backToEvent() {
            var workspace = WorkspacesManager.getWorkspace(self.workspaceFile.couple_card_id, true);
            $state.go(AppStates.root_core_navigation_event_overview, {event_id: workspace.__event._id});
        };

        this.isVendorLoggedIn = this.user.client_login_vendor_id && this.user.client_login_file_id;

    }

    return {
        scope: {
            workspaceFile: '=workspaceFile',
            user: '=user',
        },
        templateUrl : 'angular/app/modules/core/features/workspace_file/client_breadcrumbs_directive/client_breadcrumbs_directive_template.html',
        controller : ClientBreadcrumbsDirectiveController,
        controllerAs : 'clientBreadcrumbsVm',
        bindToController : true
    };
};